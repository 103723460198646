import { CaretRight } from '@phosphor-icons/react'
import * as React from 'react'

export type SettingsBreadcrumbsProps = {
  breadcrumbs: string[]
}

export const SettingsBreadcrumbs = (props: SettingsBreadcrumbsProps) => {
  const { breadcrumbs } = props

  return (
    <div className="flex flex-row items-center mb-6">
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={index}>
          <span className="text-gray-900 text-sm">{crumb}</span>
          {index !== breadcrumbs.length - 1 && breadcrumbs.length > 1 && (
            <CaretRight className="mx-1 h-3 w-3 text-gray-600" />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
