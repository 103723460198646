import * as React from 'react'
import cn from 'classnames'

export type SettingsTitleProps = {
  children: React.ReactNode
  className?: string
}

export const SettingsTitle = (props: SettingsTitleProps) => {
  const { children, className } = props

  return (
    <h1 className={cn('font-bold text-2xl mb-16', className)}>{children}</h1>
  )
}
